<template>
  <Layout>
    <PageHeader title="Create Coupon" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row">
                  <div></div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Discount Percentage</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model.number="form.percent_off"
                        placeholder="Enter Discount Percentage"
                        max="100"
                        @input="checkPercent"
                      />
                      <div class="error" v-if="$v.form.percent_off.$error">
                        <div class="error" v-if="$v.form.percent_off.$error">
                          Percentage is Required!
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Validity Days</label
                      >
                      <input
                        type="number"
                        class="form-control"
                        id="formrow-Lastname-input"
                        v-model.number="form.valid_days"
                        placeholder="Enter Validity Days"
                        @input="removeSpinner"
                      />
                      <div v-if="$v.form.valid_days.$error">
                        <div class="error" v-if="$v.form.valid_days.$error">
                          Validity Days are Required!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <SubmitButton
                    :processing="processing"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                    text="Create Coupon"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required } from "vuelidate/lib/validators";

export default {
  name: "add-sub-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      processing: false,
      loading: true,
      form: {
        percent_off: null,
        valid_days: null,
      },
    };
  },
  validations: {
    form: {
      percent_off: {
        required,
      },
      valid_days: {
        required,
      },
    },
  },
  methods: {
    checkPercent() {
      if (this.form.percent_off > 100) {
        this.form.percent_off = 100;
      }
    },
    removeSpinner(event) {
      event.target.style.webkitAppearance = "none";
      event.target.style.mozAppearance = "textfield";
      event.target.style.appearance = "none";
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.processing = true;
        console.log(this.form);
        this.$axios
          .post("coupon/create", this.form)
          .then((response) => {
            this.$router.push({ path: "/coupon/list" });
            this.triggerSwal("Coupon Created Successfully", "success");
            console.log(response.data);
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            this.handleErrorResponse(error.response, "error");
            console.log(error.response);
            this.processing = false;
          });
      }
    },
  },
};
</script>

<style scoped>
/* Hide the spinner for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>